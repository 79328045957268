// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// ** Axios Imports
import axios from "axios";
axios.defaults.withCredentials = true;

export const getFolders = createAsyncThunk("videoRecordings/getFolders", async (payload) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_ENDPOINT}/api/get-video-folders`,
      payload
    );
    return {
      data: {
        videoFolders: response.data.videoFolder,
        total: response.data.total,
      },
    };
  } catch (e) {
    return {
      data: {
        videoFolders: [],
        total: 0,
      },
    };
  }
});

export const addFolder = createAsyncThunk("videoRecordings/addFolder", async (payload) => {
  try {
    await axios.post(
      `${process.env.REACT_APP_API_ENDPOINT}/api/create-video-folder`,
      payload
    );
    return {
      data: true,
    };
  } catch (e) {
    return {
      data: false,
    };
  }
});

export const changeFolderType = createAsyncThunk("videoRecordings/changeFolderType", async (payload) => {
  try {
    await axios.post(
      `${process.env.REACT_APP_API_ENDPOINT}/api/change-video-folder-type/${payload.id}`,
      payload
    );
    return {
      data: true,
    };
  } catch (e) {
    return {
      data: false,
    };
  }
});

export const renameFolder = createAsyncThunk("videoRecordings/renameFolder", async (payload) => {
  try {
    await axios.post(
      `${process.env.REACT_APP_API_ENDPOINT}/api/update-video-folder/${payload.id}`,
      payload
    );
    return {
      data: true,
    };
  } catch (e) {
    return {
      data: false,
    };
  }
});

export const duplicateFolder = createAsyncThunk("videoRecordings/duplicateFolder", async (payload) => {
  try {
    await axios.post(
      `${process.env.REACT_APP_API_ENDPOINT}/api/duplicate-video-folder/${payload.id}`,
      payload
    );
    return {
      data: true,
    };
  } catch (e) {
    return {
      data: false,
    };
  }
});

export const folderArchiveUnarchive = createAsyncThunk("videoRecordings/folderArchiveUnarchive", async (payload) => {
  try {
    await axios.post(
      `${process.env.REACT_APP_API_ENDPOINT}/api/video-folder/archive-unarchive`,
      payload
    );
    return {
      data: true,
    };
  } catch (e) {
    return {
      data: false,
    };
  }
});

export const deleteFolder = createAsyncThunk("videoRecordings/deleteFolder", async (payload) => {
  try {
    // await axios.delete(
    //   `${process.env.REACT_APP_API_ENDPOINT}/api/video-folder/delete`,
    //   // { data: payload }
    //   { data : payload }
    // );
    await axios({
      method: 'DELETE',
      url: `${process.env.REACT_APP_API_ENDPOINT}/api/video-folder/delete`,
      data: payload,
    });

    return {
      data: true,
    };
  } catch (e) {
    return {
      data: false,
    };
  }
});

export const moveFolder = createAsyncThunk("videoRecordings/moveFolder", async (payload) => {
  try {
    await axios.post(
      `${process.env.REACT_APP_API_ENDPOINT}/api/video-folder/move-folder`,
      payload
    );
    return {
      data: true,
    };
  } catch (e) {
    return {
      data: false,
    };
  }
});

export const getActiveFolder = createAsyncThunk("videoRecordings/getActiveFolder", async (payload) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}/api/video-folder/${payload.id}`
      );
      return {
        data: response.data.VideoFolder,
      };
    } catch (e) {
      return {
        data: null,
      };
    }
  }
);

export const getVideos = createAsyncThunk("videoRecordings/getVideos", async (payload) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_ENDPOINT}/api/get-videos`,
      payload
    );
    return {
      data: {
        videos: response.data.Video,
      },
    };
  } catch (e) {
    return {
      data: {
        videos: [],
      },
    };
  }
});

export const renameVideo = createAsyncThunk("videoRecordings/renameVideo", async (payload) => {
  try {
    await axios.post(
      `${process.env.REACT_APP_API_ENDPOINT}/api/video-update/${payload.id}`,
      payload
    );
    return {
      data: true,
    };
  } catch (e) {
    return {
      data: false,
    };
  }
});

export const duplicateVideo = createAsyncThunk("videoRecordings/duplicateVideo", async (payload) => {
  try {
    await axios.post(
      `${process.env.REACT_APP_API_ENDPOINT}/api/video/duplicate-video/${payload.id}`,
      payload
    );
    return {
      data: true,
    };
  } catch (e) {
    return {
      data: false,
    };
  }
});

export const videoArchiveUnarchive = createAsyncThunk("videoRecordings/videoArchiveUnarchive", async (payload) => {
  try {
    await axios.post(
      `${process.env.REACT_APP_API_ENDPOINT}/api/video/archive-unarchive`,
      payload
    );
    return {
      data: true,
    };
  } catch (e) {
    return {
      data: false,
    };
  }
});

export const deleteVideo = createAsyncThunk("videoRecordings/deleteVideo", async (payload) => {
  try {
    await axios({
      method: 'DELETE',
      url: `${process.env.REACT_APP_API_ENDPOINT}/api/video/delete`,
      data: payload,
    });

    return {
      data: true,
    };
  } catch (e) {
    return {
      data: false,
    };
  }
});

export const moveVideo = createAsyncThunk("videoRecordings/moveVideo", async (payload) => {
  try {
    await axios.post(
      `${process.env.REACT_APP_API_ENDPOINT}/api/video/move-video`,
      payload
    );
    return {
      data: true,
    };
  } catch (e) {
    return {
      data: false,
    };
  }
});

export const getActiveVideo = createAsyncThunk("videoRecordings/getActiveVideo", async (payload) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}/api/get-video/${payload.id}`
      );
      return {
        data: response.data.Video,
      };
    } catch (e) {
      return {
        data: null,
      };
    }
  }
);

export const getWatchLaterVideos = createAsyncThunk("videoRecordings/getWatchLaterVideos", async (payload) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_ENDPOINT}/api/get-watch-later-videos`,
      payload
    );
    return {
      data: {
        videos: response.data.Video,
      },
    };
  } catch (e) {
    return {
      data: {
        videos: [],
      },
    };
  }
});

export const videoWatchLater = createAsyncThunk("videoRecordings/videoWatchLater", async (payload) => {
  try {
    await axios.post(
      `${process.env.REACT_APP_API_ENDPOINT}/api/watch-later-video/${payload.id}`,
      payload
    );
    return {
      data: true,
    };
  } catch (e) {
    return {
      data: null,
    };
  }
}
);

export const videoRecordingSlice = createSlice({
  name: "videoRecording",
  initialState: {
    videoFolders: [],
    activeVideoFolder: null,
    videos: [],
    activeVideo: null,
    watchLaterVideos: [],
    loading: true,
    currentPage: 1,
    rowsPerPage: 50,
  },
  reducers: {
    storeCurrentPage: (state, action) => {
      state.currentPage = action.payload.currentPage;
    },
    storeRowsPerPage: (state, action) => {
      state.rowsPerPage = action.payload.rowsPerPage;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getFolders.fulfilled, (state, action) => {
      state.videoFolders = action.payload.data.videoFolders;
      state.loading = false;
    })
    .addCase(getActiveFolder.fulfilled, (state, action) => {
      if (action.payload.data) {
        state.activeVideoFolder = action.payload.data;
        state.loading = false;
      }
    })
    .addCase(getVideos.fulfilled, (state, action) => {
      if (action.payload.data.videos) {
        state.videos  = action.payload.data.videos;
        state.loading = false;
      }
    })
    .addCase(getActiveVideo.fulfilled, (state, action) => {
      if (action.payload.data) {
        state.activeVideo = action.payload.data;
        state.loading = false;
      }
    })
    .addCase(getWatchLaterVideos.fulfilled, (state, action) => {
      if (action.payload.data.videos) {
        state.watchLaterVideos = action.payload.data.videos;
        state.loading = false;
      }
    });
  },
});

export const { storeCurrentPage, storeRowsPerPage } = videoRecordingSlice.actions;

export default videoRecordingSlice.reducer;
