// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// ** Axios Imports
import axios from "axios";
axios.defaults.withCredentials = true;

import toast from "react-hot-toast";

// Get User Availability API {Param: user_id}
export const getUserAvailability = createAsyncThunk(
  "user-availability",
  async (payload) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}/api/user-availability/${payload}`
      );
      // toast.success(response.data.message);
      return {
        data: response.data,
      };
    } catch (e) {
      return {
        data: [],
      };
    }
  }
);

// Create/Update User Availability API
export const updateUserAvailability = createAsyncThunk(
  "update-user-availability",
  async (payload) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/api/update-user-availability/${payload.id}`,
        payload,
      );

      toast.success(response.data.message);
      return {
        data: {
          userAvailability: response.data.userAvailability,
        },
      };
    } catch (e) {
      console.log(e);
      toast.error(e.response.data.message);
      return {
        data: {
          userAvailability: null,
        },
      };
    }
  }
);

export const userAvailabilitySlice = createSlice({
  name: "user-availability",
  initialState: {
    // Notifications table related attributes
    userAvailability: [],
    loading: true,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserAvailability.fulfilled, (state, action) => {
        state.userAvailability = action.payload.data;
        state.loading          = false;
      })
      .addCase(updateUserAvailability.fulfilled, (state, action) => {
        state.userAvailability = action.payload.data.userAvailability;
        // state.loading          = false;
      });
  },
});

export default userAvailabilitySlice.reducer;
