// ** Reducers Imports
import layout from "./layout";
import navbar from "./navbar";
import auth from "./auth";
import workspaces from "./workspaces";
import plans from "./plans";
import availability from "./availability";

import bookingPages from "./bookingPages";
import feedbacks from "./feedbacks";
import notifications from "./notifications";
import teams from "./teams";
import videoRecording from "./videoRecording";

const rootReducer = {
  navbar,
  layout,
  auth,
  workspaces,
  plans,
  feedbacks,
  notifications,
  bookingPages,
  teams,
  availability,
  videoRecording,
};

export default rootReducer;
