// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// ** Axios Imports
import axios from "axios";
axios.defaults.withCredentials = true;

// import toast from "react-hot-toast";

// Perform get teams in workspace API
export const getTeams = createAsyncThunk("teams/getTeams", async (payload) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_ENDPOINT}/api/get-teams`,
      payload
    );
    return {
      data: {
        teams: response.data.teams,
        total: response.data.total,
      },
    };
  } catch (e) {
    // toast.error(e.response.data.message);
    return {
      data: {
        teams: [],
        total: 0,
      },
    };
  }
});

// Perform get team details
export const getTeam = createAsyncThunk("teams/getTeam", async (payload) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_ENDPOINT}/api/get-team/${payload.id}`
    );
    return {
      data: {
        team: response.data.team,
      },
    };
  } catch (e) {
    // toast.error(e.response.data.message);
    return {
      data: {
        team: null,
      },
    };
  }
});

// Perform get team memebrs
export const getTeamMembers = createAsyncThunk("teams/getTeamMembers", async (payload) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_ENDPOINT}/api/get-team-members`,
      payload
    );
    return {
      data: {
        team: response.data.teams,
      },
    };
  } catch (e) {
    // toast.error(e.response.data.message);
    return {
      data: {
        team: null,
      },
    };
  }
});

export const teamsSlice = createSlice({
  name: "teams",
  initialState: {
    teams: [],
    loading: true,
    total: 0,
    currentPage: 1,
    rowsPerPage: 50,
  },
  reducers: {
    storeCurrentPage: (state, action) => {
      state.currentPage = action.payload.currentPage;
    },
    storeRowsPerPage: (state, action) => {
      state.rowsPerPage = action.payload.rowsPerPage;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getTeams.fulfilled, (state, action) => {
      state.teams = action.payload.data.teams;
      state.total = action.payload.data.total;
      state.loading = false;
    });
  },
});

export const { storeCurrentPage, storeRowsPerPage } = teamsSlice.actions;

export default teamsSlice.reducer;
