// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// ** Axios Imports
import axios from "axios";
import toast from "react-hot-toast";

axios.defaults.withCredentials = true;

// Perform get active booking pages
export const getBookingPages = createAsyncThunk(
  "bookingPages/getBookingPages",
  async (payload) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/api/get-booking-pages`,
        payload
      );
      // toast.success(response.data.message);
      return {
        data: {
          bookingPages: response.data.bookingPages,
          total: response.data.total,
        },
      };
    } catch (e) {
      return {
        data: {
          bookingPages: [],
          total: 0,
        },
      };
    }
  }
);

// Perform get active booking page
export const getActiveBookingPage = createAsyncThunk(
  "get-active-booking-page",
  async (payload) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}/api/booking-page/${payload}`
      );
      return {
        data: response.data,
      };
    } catch (e) {
      return {
        data: [],
      };
    }
  }
);

// Create/Update Booking Availability API
export const updateBookingAvailability = createAsyncThunk(
  "update-booking-availability",
  async (payload) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/api/update-booking-availability/${payload.id}`,
        payload,
      );

      toast.success(response.data.message);
      return {
        data: {
          bookingPage: response.data.bookingPage,
        },
      };
    } catch (e) {
      console.log(e);
      toast.error(e.response.data.message);
      return {
        data: {
          bookingPage: null,
        },
      };
    }
  }
);

// Create/Update Booking Times & Availability Meta API
export const updateBookingAvailabilityMeta = createAsyncThunk(
  "update-booking-availability-meta",
  async (payload) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/api/update-booking-availability-meta/${payload.id}`,
        payload,
      );

      toast.success(response.data.message);
      return {
        data: {
          bookingPage: response.data.bookingPage,
        },
      };
    } catch (e) {
      console.log(e);
      toast.error(e.response.data.message);
      return {
        data: {
          bookingPage: null,
        },
      };
    }
  }
);

export const bookingPagesSlice = createSlice({
  name: "bookingPages",
  initialState: {
    bookingPages: [],
    bookingPageObj: {},
    loading: true,
    total: 0,
    currentPage: 1,
    rowsPerPage: 50,
    viewType: 'table'
  },
  reducers: {
    storeCurrentPage: (state, action) => {
      state.currentPage = action.payload.currentPage;
    },
    storeRowsPerPage: (state, action) => {
      state.rowsPerPage = action.payload.rowsPerPage;
    },
    storeViewType: (state, action) => {
      state.viewType = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getBookingPages.fulfilled, (state, action) => {
      state.bookingPages = action.payload.data.bookingPages;
      state.total = action.payload.data.total;
      state.loading = false;
    })
    .addCase(getActiveBookingPage.fulfilled, (state, action) => {
      state.bookingPageObj = action.payload.data.bookingPage;
      state.loading        = false;
    }) 
    .addCase(updateBookingAvailability.fulfilled, (state, action) => {
      state.bookingPageObj = action.payload.data.bookingPage;
      state.loading        = false;
    })
    .addCase(updateBookingAvailabilityMeta.fulfilled, (state, action) => {
      state.bookingPageObj = action.payload.data.bookingPage;
      state.loading        = false;
    });
  },
});

export const { storeCurrentPage, storeRowsPerPage, storeViewType } = bookingPagesSlice.actions;

export default bookingPagesSlice.reducer;
